<template>
  <div>
    <div class="item-title__box">
      <img class="img" :src="expert.avatarUrl" />
      <div class="title-box">
        <div class="title-top">
          <div>{{ expert.name }}</div>
          <span>{{ expert.title }}</span>
        </div>
        <div class="title-bottom">
          <div class="bottom-tips">专家介绍</div>
          <div class="bottom-content">{{ expert.intro }}</div>
        </div>
      </div>
    </div>
    <div class="field-box">
      <textarea
        class="comment-box__input"
        v-model="inputText"
        maxlength="500"
        placeholder="请填写提问内容"
      ></textarea>
    </div>
    <div
      v-bind:class="[
        'btn-box',
        inputText.trim() !== '' ? 'btn-box__click' : ' btn-box__not',
      ]"
      class="btn-box"
      @click="onSubmit"
    >
      提交
    </div>
    <subscription-tip :show.sync="show"></subscription-tip>
  </div>
</template>

<script>
import { ask } from "@/api/qa";
import to from "@/utils/to";
import SubscriptionTip from "@/components/SubscriptionTip";
export default {
	components: {
		SubscriptionTip
	},
	data() {
		return {
			loading: false,
			inputText: "",
			expert: {},
			show: false,
		};
	},
	mounted() {
		this.expert = JSON.parse(this.$route.query.desc);
	},
	methods: {
		async onSubmit() {
			if (this.inputText.trim().length <= 0) {
				return;
			}

			const data = {
				expertId: this.expert.id,
				content: this.inputText,
			};

			this.$showLoading("提交中...");
			/* eslint-disable */
			let [err, res] = await to(ask(data));
			/* eslint-enable*/
			this.$hideLoading();

			if (err) {
				this.show=true
        // this.$showFail("提交失败");
				return;
			}

			this.$showSuccess("提交成功", () => {
				this.$router.go(-1);
			});

			return;
		},
	},
};
</script>

<style scoped>
.item-title__box {
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	padding: 16px;
	text-align: left;
	display: flex;
}

.img {
	/* width: 70px; */
	height: 70px;
}

.title-box {
	flex: 1;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	color: #333333;
	margin-left: 8px;
}

.title-top {
	display: flex;
	align-items: flex-end;
}

.title-top div {
	font-size: 16px;
	font-weight: 700;
	color: #333333;
	text-align: left;
}

.title-top span {
	margin-left: 8px;
}

.title-bottom {
	margin-top: 4px;
	padding: 0 4px;
	background: #f3f7fa;
	border-radius: 4px;
	font-size: 12px;
	line-height: 17px;
	color: #999999;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 12px;
}

.bottom-tips {
	padding: 2px 8px 2px 4px;
	background: #ffc83e;
	border-radius: 4px 0px 12px 0px;
	font-size: 10px;
	line-height: 14px;
	color: #333333;
	display: flex;
	align-items: center;
}

.bottom-content {
	margin: 8px 0 0 4px;
}

.field-box {
	padding: 16px;
}

.comment-box__input {
	font-size: 16px;
	width: 340px;
	height: 190px;
	border: 1px solid #cccccc;
	border-radius: 8px;
	background: #f3f7fa;
	font-weight: 500;
	padding: 12px;
}

.btn-box__not {
	background: #c4c4c4;
}

.btn-box__click {
	background: #ffc83e;
}

.btn-box {
	/* left: 64px;
	position: fixed;
	bottom: 10px; */
	width: 248px;
	padding: 10px 110px;
	border-radius: 20px;
	margin: 0 auto;
	/* display: flex;
	align-items: center;
	justify-content: center; */
	font-size: 14px;
	line-height: 25px;
	color: #ffffff;
}
</style>
