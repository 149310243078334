<template>
  <van-popup
    :value="show"
    :close-on-click-overlay="false"
    get-container="body"
		style="background-color:transparent"
  >
    <div class="container">
      <img
        class="qr"
        src="https://annualr.oss-cn-chengdu.aliyuncs.com/hxxq/qr.png"
      />
      <img
        class="close"
        src="@/assets/imgs/subscription/close.png"
        @click="close()"
      />
    </div>
  </van-popup>
</template>

<script>
import { getSubscribeStatus } from "@/api/user";
import to from "@/utils/to";
import { Popup } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getSubscribeStatus();
  },
  methods: {
    async getSubscribeStatus() {
      let [err, res] = await to(getSubscribeStatus());
      if (err) {
        return;
      }
      this.$emit("update:show", !res.subscribeStatus);
    },
    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.qr {
  width: 276px;
  margin-bottom: 10px;
}

.close {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
</style>
